import type { SVGProps } from "react"

interface IArrowIconProps extends SVGProps<SVGSVGElement> {
  color: "white" | "black"
}

export default function ArrowIcon(props: IArrowIconProps) {
  const { color, className, ...otherProps } = props

  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`arrow ${className}`}
      {...otherProps}
    >
      <path
        d="M12.4808 23.1141L20.0582 15.5378L20.0576 22.2905L22.3142 22.2921L22.3147 11.6818L11.7118 11.6812L11.7124 13.9389L18.465 13.9383L10.8861 21.5173L12.4808 23.1141Z"
        fill={color === "black" ? "#101012" : "white"}
      />
    </svg>
  )
}
