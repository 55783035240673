import { Fragment } from "react"
import AdvantagesContent from "../common/constants/advantages-content"

export default function Advantages() {
  return (
    <div
      id="advantages"
      className="mb-96 md:grid grid-cols-section grid-flow-row gap-x-28 gap-y-20"
    >
      <div className="mb-14 md:mb-0 md:pt-4">
        <h2 className="font-bold text-5xl md:text-3xl">Go Ahead Advantages</h2>
      </div>
      <div className="col-start-2 col-end-4 grid grid-cols-2 border-t border-main-gray">
        {AdvantagesContent.map((block, index) => (
          <Fragment key={index}>
            <div
              className={
                index < AdvantagesContent.length - 1
                  ? "border-b border-main-gray"
                  : ""
              }
            >
              <p className="py-5 text-xl border-b border-main-gray">
                Step {index + 1}
              </p>
            </div>
            <div
              className={
                index < AdvantagesContent.length - 1
                  ? "border-b border-main-gray"
                  : ""
              }
            >
              <h3 className="mb-5 py-5 font-normal text-xl border-b border-main-gray">
                {block.title}
              </h3>
              {block.text && <p className="mb-14">{block.text}</p>}
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  )
}
