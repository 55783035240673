import NavigationLinks from "../common/constants/navigation-links"
import NavigationLink from "../common/ui/navigation-link"

export default function Footer() {
  return (
    <>
      {/* Desktop */}
      <div className="mx-[-5rem] px-[5rem] py-10 hidden md:grid grid-cols-section gap-x-28 text-main-white bg-dark-black">
        <div>
          <h2 className="mb-10 font-bold text-7xl uppercase text-main-green">
            Go Ahead
          </h2>
          <div className="grid grid-cols-2">
            <div>
              <p className="mb-1 text-main-gray">Phone</p>
              <div className="mb-3">
                <a href="tel:+971551495276">+971551495276</a>
              </div>
              <p className="mb-1 text-main-gray">Email</p>
              <a href="mailto:inbox@goaheadbureau.ae">inbox@goaheadbureau.ae</a>
            </div>
            <div>
              <p className="mb-1 text-main-gray">Address</p>
              <p>
                Dubai Silicon Oasis
                <br />
                DDP, Building A1, Dubai
                <br />
                United Arab Emirates
              </p>
            </div>
          </div>
        </div>
        <div className="col-start-2 col-end-4 flex flex-col justify-between">
          <div className="flex justify-between text-lg">
            {NavigationLinks.map((link, index) => (
              <NavigationLink key={index} {...link} />
            ))}
            <p className="text-link cursor-pointer">Privacy policy</p>
            <p className="text-link cursor-pointer">Terms of use</p>
          </div>
          <p className="text-sm underline">
            © Copyright 2023, GO AHEAD all rights reserved
          </p>
        </div>
      </div>

      {/* Mobile */}
      <div className="mx-[-5rem] px-[5rem] pb-20 grid md:hidden grid-cols-2 text-main-white bg-dark-black">
        <h2
          className="font-bold text-7xl uppercase text-main-green whitespace-nowrap -rotate-180"
          style={{ writingMode: "vertical-rl" }}
        >
          Go Ahead
        </h2>
        <div className="flex flex-col justify-between">
          <div>
            <p className="mb-1 text-main-gray">Phone</p>
            <div className="mb-3">
              <a href="tel:+971551495276">+971551495276</a>
            </div>
            <p className="mb-1 text-main-gray">Email</p>
            <div className="mb-3">
              <a href="mailto:inbox@goaheadbureau.ae">inbox@goaheadbureau.ae</a>
            </div>
            <p className="mb-1 text-main-gray">Address</p>
            <p>
              Dubai Silicon Oasis
              <br />
              DDP, Building A1, Dubai
              <br />
              United Arab Emirates
            </p>
          </div>
          <div>
            <div>
              <p className="text-link cursor-pointer underline">
                Privacy policy
              </p>
            </div>
            <div>
              <p className="text-link cursor-pointer underline">Terms of use</p>
            </div>
          </div>
          <p className="underline">
            © Copyright 2023
            <br />
            GO AHEAD all rights reserved
          </p>
        </div>
      </div>
    </>
  )
}
