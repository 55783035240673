import heroMobileImage from "../assets/images/hero-mobile.png"
import heroImage from "../assets/images/hero.png"
import TextLink from "../common/ui/text-link"

export default function Hero() {
  return (
    <div className="mb-20">
      <div className="z-10 relative top-24 mb-24 md:mb-0">
        <p className="w-3/5 md:w-96 mb-7">
          At Go Ahead Bureau, we use various tools and programming languages to
          create customized software for our clients. Our services include IoT
          communication via satellite, blockchain solutions for finance and real
          estate industries, and cybersecurity services to help clients achieve
          their software-related goals. We ensure that your strategy is aligned
          with execution and delivery, fulfilling your needs and surpassing your
          expectations.
        </p>
        <TextLink href="#contact-us">Start project</TextLink>
      </div>
      <div className="z-0 mx-[-2rem] md:mx-[-5rem] relative md:bottom-5">
        <img src={heroImage} alt="" className="w-full hidden md:block" />
        <img src={heroMobileImage} alt="" className="w-full md:hidden" />
        <div className="absolute top-[20.3rem] md:top-[18.5rem] left-[1rem] md:left-[5rem] overflow-hidden">
          <h1 className="font-bold text-[5.5rem] md:text-9xl uppercase -translate-x-full animate-ltr-slide-in">
            Go ahead
          </h1>
        </div>
        <div className="absolute top-[26.5rem] md:top-[26.5rem] left-[1rem] md:left-[42rem] overflow-hidden">
          <h1
            className="font-bold text-[5.5rem] md:text-9xl uppercase -translate-x-full animate-ltr-slide-in"
            style={{ animationDelay: "1s" }}
          >
            Bureau
          </h1>
        </div>
      </div>
    </div>
  )
}
