import { INavigationLink } from "../constants/navigation-links"

export default function NavigationLink(props: INavigationLink) {
  const { title, url } = props

  const isUrlToElement = url.includes("#")

  const handleNavigateClick = () => {
    const element = document.querySelector(url)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <>
      {isUrlToElement ? (
        <button onClick={handleNavigateClick} className="text-link">
          {title}
        </button>
      ) : (
        <a
          href={url}
          target="_blank"
          rel="noreferrer noopener"
          className="text-link"
        >
          {title}
        </a>
      )}
    </>
  )
}
