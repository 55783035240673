import circleImage1 from "../../assets/images/circle-1.svg"
import circleImage2 from "../../assets/images/circle-2.svg"
import circleImage3 from "../../assets/images/circle-3.svg"
import circleImage4 from "../../assets/images/circle-4.svg"

interface IAboutUsContentBlock {
  image: string
  title: string
  text: string
}

const AboutUsContent: IAboutUsContentBlock[] = [
  {
    image: circleImage1,
    title: "Software Development",
    text: "At our company, we pride ourselves on offering a comprehensive range of services to our clients. We offer mobile, web, and desktop app development using various tools and programming languages to meet user needs. Our team creates intuitive and high-quality software with user-friendly designs. We are committed to staying up to date with the latest trends and technologies in the industry.",
  },
  {
    image: circleImage2,
    title: "IoT satellite communication",
    text: "Businesses use IoT to remotely manage assets, ensure worker safety, and improve supply chain operations. IoT can monitor perishable goods and pilot merchant ships from shore. But since cellular coverage is limited to 15% of the planet, companies are turning to satellite communications for wider reach. This has given rise to a new category of IoT, called satellite IoT.. ",
  },
  {
    image: circleImage3,
    title: "Cybersecurity",
    text: "Cybersecurity is crucial for protecting company and customer data. Stolen data can lead to costly efforts to retrieve files, regain customer trust, and repair a damaged reputation. However, many companies do not do enough. Custom applications are better than off-the-shelf software because they are tailored to a company's existing processes. The benefits of custom applications for cybersecurity are clear. By using tailored software, companies can more effectively safeguard their data and minimize the risk of costly data breaches.",
  },
  {
    image: circleImage4,
    title: "Blockchain",
    text: "Blockchain is revolutionizing industries including fintech, real estate, and supply chain management. Companies work with Go Ahed Bureau’s blockchain consulting services to augment their application development teams for cryptocurrency and blockchain-based development for any customer requests.",
  },
]

export default AboutUsContent
