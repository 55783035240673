import { useState } from "react"
import { useForm } from "react-hook-form"
import ArrowIcon from "../common/ui/arrow-icon"
import Input from "../common/ui/input"

export default function ContactUs() {
  const [isServerError, setIsServerError] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    defaultValues: { name: "", phone: "", email: "", message: "" },
  })

  const message = watch("message")

  const onSubmit = async (data: any) => {
    try {
      await fetch("/api/send-feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      reset()
    } catch (error) {
      console.error(error)
      setIsServerError(true)
      setTimeout(() => setIsServerError(false), 3000)
    }
  }

  return (
    <div
      id="contact-us"
      className="relative mx-[-5rem] pb-14 overflow-x-clip text-main-white bg-dark-black"
    >
      <div className="absolute h-full w-[150%] top-0 left-1/2 -translate-y-[20%] -translate-x-1/2 rounded-[100%] bg-dark-black" />
      <div className="relative px-[5rem] md:grid grid-cols-section grid-flow-row gap-x-28 gap-y-20">
        <div className="mb-14 md:mb-0">
          <h2 className="font-bold text-3xl">Contact us</h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="h-28">
            <Input
              label="Name*"
              type="text"
              placeholder="John Doe"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <p className="mt-1 text-sm text-red-500">
                Name field is required
              </p>
            )}
          </div>
          <div className="h-28">
            <Input
              label="Phone*"
              type="tel"
              placeholder="+1 234 567 8910"
              {...register("phone", { required: true })}
            />
            {errors.phone && (
              <p className="mt-1 text-sm text-red-500">
                Phone field is required
              </p>
            )}
          </div>
          <div className="h-28">
            <Input
              label="Email*"
              type="email"
              placeholder="example@email.com"
              {...register("email", { required: true })}
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-500">
                Email field is required
              </p>
            )}
          </div>
          <label>
            <p className="mb-3 font-normal text-lg">Your message or idea</p>
            <textarea
              rows={3}
              className="w-full mb-1 py-2 px-2 resize-none font-normal border-y border-main-white bg-transparent"
              {...register("message", { maxLength: 264 })}
            />
            <div className="mb-3 grid grid-cols-4">
              {errors.message && (
                <p className="col-start-1 col-end-4 text-sm text-red-500">
                  Too many characters
                </p>
              )}
              <p className="col-start-4 text-sm text-right">
                <span className={errors.message ? "text-red-500" : ""}>
                  {message?.length || 0}
                </span>
                /264
              </p>
            </div>
          </label>
          {isServerError && (
            <p className="mb-1 text-sm text-red-500">Server error</p>
          )}
          <button
            type="submit"
            className="text-link mb-2 flex items-end font-semibold text-2xl"
          >
            <span className="mr-1 underline">Lets go</span>
            <ArrowIcon color="white" className="h-8 w-8" />
          </button>
          <p className="text-sm">
            By submitting the form you confirm your <br /> agreement with the
            privacy policy
          </p>
        </form>
      </div>
    </div>
  )
}
