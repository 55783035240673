import circleBigImage from "../assets/images/circle-big.svg"
import AboutUsContent from "../common/constants/about-us-content"
import TextLink from "../common/ui/text-link"

export default function AboutUs() {
  return (
    <div
      id="about-us"
      className="mb-36 md:grid grid-cols-section grid-flow-row gap-x-28 gap-y-20"
    >
      <div className="mb-14 md:mb-0 md:border-b border-main-gray">
        <h2 className="font-bold text-5xl md:text-3xl">About us</h2>
      </div>
      {AboutUsContent.map((block, index) =>
        index < 2 ? (
          <div key={index} className="mb-14 md:mb-0 flex flex-col">
            <img src={block.image} alt="" className="mb-7 w-14" />
            <h3 className="mb-5 font-normal text-xl">{block.title}</h3>
            <p className="pb-10 border-b border-main-gray">{block.text}</p>
          </div>
        ) : null
      )}
      <div className="hidden md:block border-b border-main-gray">
        <img src={circleBigImage} alt="" className="w-80" />
      </div>
      {AboutUsContent.map((block, index) =>
        index > 1 ? (
          <div key={index} className="mb-14 md:mb-0 flex flex-col">
            <img src={block.image} alt="" className="mb-7 w-14" />
            <h3 className="mb-5 font-normal text-xl">{block.title}</h3>
            <p className="pb-10 border-b border-main-gray">{block.text}</p>
            {index === 3 && (
              <div className="flex-grow flex flex-col justify-end">
                <TextLink className="mt-10">Learn more</TextLink>
              </div>
            )}
          </div>
        ) : null
      )}
    </div>
  )
}
