import type { AnchorHTMLAttributes, DetailedHTMLProps } from "react"
import ArrowIcon from "./arrow-icon"

interface ITextLinkProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  children: string
}

export default function TextLink(props: ITextLinkProps) {
  const { children, className, ...otherProps } = props

  return (
    <a
      className={`w-max text-link flex items-end font-semibold text-2xl cursor-pointer ${className}`}
      {...otherProps}
    >
      <span className="mr-1 underline">{children}</span>
      <ArrowIcon color="black" className="h-8 w-8" />
    </a>
  )
}
