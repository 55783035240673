import type { DetailedHTMLProps, InputHTMLAttributes } from "react"
import { forwardRef } from "react"

interface IInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string
}

const Input = forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
  const { label, className, ...otherProps } = props

  return (
    <label>
      <p className="mb-3 font-normal text-lg">{label}</p>
      <input
        ref={ref}
        type="text"
        className={`w-full py-2 px-2 font-normal border-y border-main-white bg-transparent ${className}`}
        {...otherProps}
      />
    </label>
  )
})

export default Input
