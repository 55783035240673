import logoImage from "../assets/images/logo.svg"
import NavigationLinks from "../common/constants/navigation-links"
import NavigationLink from "../common/ui/navigation-link"

export default function Header() {
  return (
    <header className="py-7 hidden md:grid grid-cols-section gap-x-28">
      <img src={logoImage} alt="Go Ahead" className="w-24" />
      <div className="col-start-2 col-end-4 flex justify-between text-lg">
        {NavigationLinks.map((link, index) => (
          <NavigationLink key={index} {...link} />
        ))}
        <p className="text-link cursor-pointer">Privacy policy</p>
        <p className="text-link cursor-pointer">Terms of use</p>
      </div>
    </header>
  )
}
