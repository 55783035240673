export interface INavigationLink {
  title: string
  url: string
}

const NavigationLinks: INavigationLink[] = [
  {
    title: "About us",
    url: "#about-us",
  },
  {
    title: "GO AHEAD team",
    url: "#advantages",
  },
  {
    title: "Contact us",
    url: "#contact-us",
  },
]

export default NavigationLinks
