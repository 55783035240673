import AboutUs from "./components/about-us"
import Advantages from "./components/advantages"
import ContactUs from "./components/contact-us"
import Footer from "./components/footer"
import Header from "./components/header"
import Hero from "./components/hero"

export default function App() {
  return (
    <>
      <Header />
      <main className="flex flex-col">
        <Hero />
        <AboutUs />
        <Advantages />
        <ContactUs />
      </main>
      <Footer />
    </>
  )
}
