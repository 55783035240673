interface IAdvantagesContentBlock {
  title: string
  text: string
}

const AdvantagesContent: IAdvantagesContentBlock[] = [
  {
    title: "True Expertise",
    text: "Technology and industry experts with average 10 years experience across software product development, cloud, and data intelligence. We provide not only software development services, but also consultations on implementation and support. We stay in touch throughout the entire process and are ready to solve any issues that may arise.",
  },
  {
    title: "Time to Value",
    text: "Our proven frameworks and integrated teams allow you to accelerate time to value while minimizing risk. One of the benefits of these proven frameworks is that they can be customized to fit your unique needs. Additionally, our integrated teams work closely with your organization to ensure that you are receiving the highest quality service possible. By leveraging our expertise and experience, you can be confident that you are making the right decisions for your business.",
  },
  {
    title: "Peace of Mind",
    text: "Our company offers a full range of services to help you achieve your software goals. We specialize in connecting your strategy to execution, so your vision is brought to life. Our approach covers every step of the way, from planning to delivery. We make accountability easy, ensuring you always know what's happening with your project.",
  },
  {
    title: "Coming Soon",
    text: "",
  },
]

export default AdvantagesContent
